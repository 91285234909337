
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    props: ['blok'],
    components: { VueSlickCarousel },
    data(){
        return {
            settings : {
                arrows: true,
                centerMode: true,
                centerPadding: "20px",
                focusOnSelect: true,
                infinite: true,
                slidesToShow: 3,
                speed: 500,
                autoplay: true,
                autoplaySpeed: 3000
            }
        }
    },
    computed: {
        displayMode(){
            return this.$store.state.layout.displayMode
        }
    }
}
